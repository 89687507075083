var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-toolbar',{attrs:{"title":_vm.$t('Recherches'),"total":_vm.$store.getters['search/total']},on:{"refresh":_vm.getSearches}}),_c('v-card',{staticClass:"mb-2 mt-2"},[_c('v-alert',{attrs:{"color":_vm.error ? 'error' : 'success',"dismissible":"","text":"","transition":"slide-y-transition"},model:{value:(_vm.isAlertVisible),callback:function ($$v) {_vm.isAlertVisible=$$v},expression:"isAlertVisible"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('v-card-title',{staticClass:"ml-4",staticStyle:{"padding-top":"2px","padding-bottom":"2px"}}),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"marginHeader"},[_c('v-col',{staticClass:"marginCity",attrs:{"cols":"12","md":"3","sm":"3","xs":"3"}},[_c('city-input',{attrs:{"label":'Rechercher par la ville de départ',"placeholder":'Rechercher par la ville de départ',"rules":_vm.rules.city},on:{"change":_vm.filterItems,"getCity":_vm.getStartOmsId},model:{value:(_vm.$store.state.search.field.city),callback:function ($$v) {_vm.$set(_vm.$store.state.search.field, "city", $$v)},expression:"$store.state.search.field.city"}})],1),_c('v-col',{staticClass:"marginCity",attrs:{"cols":"12","md":"3","sm":"3","xs":"3"}},[_c('city-input',{attrs:{"label":'Rechercher par la destination',"placeholder":'Rechercher par la destination',"rules":_vm.rules.destination},on:{"change":_vm.filterItems,"getCity":_vm.getEndOmsId},model:{value:(_vm.$store.state.search.field.destination),callback:function ($$v) {_vm.$set(_vm.$store.state.search.field, "destination", $$v)},expression:"$store.state.search.field.destination"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3","xs":"3"}},[_c('v-menu',{ref:"menuref",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filtrer par date de départ","outlined":"","dense":"","hide-details":"auto","rules":_vm.rules.start_date,"clearable":""},on:{"input":_vm.getSearchesByFields},model:{value:(_vm.$store.state.search.field.start_date),callback:function ($$v) {_vm.$set(_vm.$store.state.search.field, "start_date", $$v)},expression:"$store.state.search.field.start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1950-01-01","color":"primary"},on:{"change":_vm.save,"input":_vm.getSearchesByFields},model:{value:(_vm.$store.state.search.field.start_date),callback:function ($$v) {_vm.$set(_vm.$store.state.search.field, "start_date", $$v)},expression:"$store.state.search.field.start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3","xs":"3"}},[_c('v-menu',{ref:"menuref",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filtrer par date de creation","outlined":"","dense":"","hide-details":"auto","rules":_vm.rules.created_at,"clearable":""},on:{"input":_vm.getSearchesByFields},model:{value:(_vm.$store.state.search.field.created_at),callback:function ($$v) {_vm.$set(_vm.$store.state.search.field, "created_at", $$v)},expression:"$store.state.search.field.created_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1950-01-01","color":"primary"},on:{"change":_vm.save,"input":_vm.getSearchesByFields},model:{value:(_vm.$store.state.search.field.created_at),callback:function ($$v) {_vm.$set(_vm.$store.state.search.field, "created_at", $$v)},expression:"$store.state.search.field.created_at"}})],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.isDialogVisible,"loading-text":"Chargement...","headers":_vm.headers,"items":_vm.$store.getters['search/searches'],"items-per-page":_vm.$store.getters['search/per_page'],"page":_vm.$store.getters['search/current_page'],"dense":"","fixed-header":"","height":"500","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.$store.getters, 'search/current_page', $event)},"page-count":function($event){_vm.currentPage = $event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user !== null ? (item.user.name !== null ? item.user.name : item.user.phone_number) : null)+" ")]}},{key:"item.nb_passagers_left",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.nb_passagers_left > 0 ? 'success' : 'error',"small":""}},[_vm._v(" "+_vm._s(item.nb_passagers_left + '/' + item.nb_passagers)+" ")])]}},{key:"item.global_amount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":new Date(item.start_date) > new Date() ? 'primary' : 'error',"small":""}},[_vm._v(" "+_vm._s(_vm.formatAmount(item.global_amount))+" ")])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.start_date))))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.created_at))))+" ")]}},{key:"item.cancelled_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.cancelled_at !== null),expression:"item.cancelled_at !== null"}],attrs:{"color":"error","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.cancelled_at ? _vm.formattingDate(("" + (item.cancelled_at))) : null))])])]}},{key:"item.car",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.car !== null ? ((item.car.marque) + " " + (item.car.model) + " " + (item.car.release_year)) : null)+" ")]}}],null,true)}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('v-text-field',{attrs:{"value":_vm.$store.state.search.field.paginate,"label":"Lignes par page:","type":"number","min":"-1","max":"15","hide-details":""},on:{"input":_vm.getSearchesPerPage}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.lastPage},on:{"input":_vm.getSearches},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Voulez-vous bloquer cet utilisateur? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Non ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" Oui ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }